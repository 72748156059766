import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AppContext } from "../Context/AppContext";
import { EditContext } from "../Context/EditContext";
import { format } from "../intl.js";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { useParams } from "react-router-dom";
import moment from "moment";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { StatsContext } from "../Context/StatsContext";
import {
  Grid,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Paper,
  Drawer,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import { getInvoices, deleteInvoice } from "../actions/invoiceActions";
import { getMeetings, deleteMeeting } from "../actions/meetingActions";
import { getClient, getAllClients } from "../actions/clientActions";
import { getUsers } from "../actions/auth";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import MeetingForm from "../components/Meetings/MeetingForm";
import NoData from "../components/svgIcons/NoData";
import { useSnackbar } from "react-simple-snackbar";
import MenuIcon from "@material-ui/icons/Menu";
import Statement from "./adminLogin/Statement";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    height: "100%",
  },
  textMargin: {
    marginLeft: theme.spacing(10),
  },
  gridContainer: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: "10rem", // Apply left margin of 10rem on screens larger than or equal to small (sm)
    },
  },
  openDrawerButton: {
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "10rem",
    },
  },
  clientDetailsContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
}));
const tableStyle = {
  width: 160,
  fontSize: 14,
  cursor: "pointer",
  borderBottom: "none",
  padding: "8px",
  textAlign: "center",
};
const headerStyle = { borderBottom: "none", textAlign: "center" };

const Stats = () => {
  const classes = useStyles();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(0);
  const { currStat, setCurrStat } = useContext(StatsContext);
  const history = useHistory();
  const { invoiceId } = useParams();

  console.log("InvoiceId", invoiceId);
  const { showForm, setShowForm } = useContext(AppContext);
  const { currId, setCurrId } = useContext(EditContext);
  const [clientDetails, setClientDetails] = useState(null);
  const [client, setClient] = useState(null);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const user = JSON.parse(localStorage.getItem("profile"));
  const { users } = useSelector((state) => state.auth);
  const { clients } = useSelector((state) => state.clients);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [searchQuery, setSearchQuery] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [clientName, setClientName] = useState("");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const dispatch = useDispatch();
  const invoices = useSelector((state) => state.invoices.invoices);

  const { meetings } = useSelector((state) => state.meetings);

  const isLoading = useSelector((state) => state.meetings.isLoading);
  // Simulating fetching client details based on the client ID
  useEffect(() => dispatch(getInvoices()), [location, dispatch, invoiceId]);
  useEffect(() => dispatch(getMeetings()), [location, dispatch]);
  useEffect(() => {
    dispatch(getUsers()); // Replace `getUsers` with the actual action to fetch users
  }, []);
  useEffect(() => dispatch(getAllClients()), [location]);
  useEffect(() => {
    const invoice = invoices.find((inv) => inv._id === invoiceId);
    const client = clients.find((client) => client._id === invoiceId);

    // console.log("Client",client.name)
    // setClientName(client.name);
    if (invoice) {
      const filteredInv = invoices.filter(
        (inv) => inv.client.phone === invoice?.client?.phone
      );
      console.log("filtered", filteredInv);
      setFilteredInvoices(filteredInv);

      const filteredRep = meetings.filter(
        (rep) => rep.client.phone === invoice?.client?.phone
      );
      console.log("filtered Reports", filteredRep);
      setFilteredReports(filteredRep);
      if (invoice && invoice.client) {
        const fetchedClientDetails = {
          id: invoice._id,
          name: invoice.client.name,
          email: invoice.client.email,
          phone: invoice.client.phone,
          address: invoice.client.address,
          contactPerson: invoice.client.contactPerson,
        };
        //  setClientName(client.name);
        setClientDetails(fetchedClientDetails);
      }
    } else {
      const filteredInv = invoices.filter(
        (inv) => inv.client._id === client?._id
      );
      console.log("filtered", filteredInv);
      setFilteredInvoices(filteredInv);

      const filteredRep = meetings.filter(
        (rep) => rep.client._id === client?._id
      );
      console.log("filtered Reports", filteredRep);
      setFilteredReports(filteredRep);
      setClient(client);
      // setClientName(client.name);
    }
  }, [invoiceId, invoices, meetings, clients]);
  //
  const toCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  function checkStatus(status) {
    return status === "Partial"
      ? {
          border: "solid 0px #1976d2",
          backgroundColor: "#baddff",
          padding: "8px 10px",
          borderRadius: "20px",
          margin: "10px",
        }
      : status === "Paid"
      ? {
          border: "solid 0px green",
          backgroundColor: "#a5ffcd",
          padding: "8px 18px",
          borderRadius: "20px",
        }
      : status === "Unpaid"
      ? {
          color: "#ff0000",
          width: 160,
          fontSize: 14,
          cursor: "pointer",
          borderBottom: "none",
          padding: "8px",
          textAlign: "center",
        }
      : "red";
  }
  const handleViewInvoice = (id) => {
    history.push(`/invoice/${id}`);
  };
  const handleEditInvoice = (id) => {
    history.push(`/edit/invoice/${id}`);
  };
  const handleDeleteInvoice = (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this client?"
    );
    if (confirmDelete) {
      dispatch(deleteInvoice(id, openSnackbar));
    }
  };
  // const handleDeleteInvoice = (invoice) => {};

  const findUserNameById = (userId) => {
    console.log("parameter" + userId);
    const user = users.find((user) => user._id == userId);
    console.log(user);
    return user ? user.name : "";
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const handleCloseForm = () => {
    setShowForm(false);
  };
  const handleInputChange = (value) => {
    console.log(value);
    setCurrId(value);
    setShowForm(true);
  };
  const handleDeleteMeeting = (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this client?"
    );
    if (confirmDelete) {
      dispatch(deleteMeeting(id, openSnackbar));
    }
  };
  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };
  const filteredRows = filteredInvoices.filter((row) =>
    findUserNameById(row.creator)
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );
  const filteredReportsRows = filteredReports.filter((row) =>
    findUserNameById(row.creator)
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  //
  return (
    <>
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            aria-label=""
            onClick={handleOpenDrawer}
            className={classes.openDrawerButton}
          >
            <AccountCircleIcon style={{ fontSize: 40 }} />
          </IconButton>
          <Typography
            variant="h6"
            style={{
              fontWeight: "bold",
              color: "#333",
              marginLeft: "10px",
            }}
          >
            {client?.name}
          </Typography>
          <Typography
            variant="h6"
            style={{
              fontWeight: "bold",
              color: "#333",
              marginLeft: "10px",
            }}
          >
            {client?.agentCode}
          </Typography>
        </div>

        <Drawer
          className={classes.drawer}
          variant="temporary"
          anchor="Top"
          open={openDrawer}
          onClose={handleCloseDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {/* ... */}

          {/* <Paper elevation={3} className={classes.clientDetailsPaper}> */}
          <div className={classes.clientDetailsContainer}>
            <Typography
              variant="h5"
              gutterBottom
              className={classes.textMargin}
            >
              Hotel/Agents Details
            </Typography>
            {clientDetails ? (
              <div>
                <Typography variant="body1" className={classes.textMargin}>
                  <strong>Hotel/Agents:</strong> {clientDetails.name}
                </Typography>
                {/* <Typography variant="body1" className={classes.textMargin}>
                  <strong>Contact Person :</strong> {clientDetails.contactPerson}
                </Typography>
                <Typography variant="body1" className={classes.textMargin}>
                  <strong>Email:</strong> {clientDetails.email}
                </Typography> */}
                <Typography variant="body1" className={classes.textMargin}>
                  <strong>Phone:</strong> {clientDetails.phone}
                </Typography>
                <Typography variant="body1" className={classes.textMargin}>
                  <strong>Agent Code:</strong> {clientDetails.agentCode}
                </Typography>
              </div>
            ) : client ? (
              <div>
                <Typography variant="body1" className={classes.textMargin}>
                  <strong>Hotel/Agents :</strong> {client.name}
                </Typography>
                {/* <Typography variant="body1" className={classes.textMargin}>
                  <strong>Contact Person :</strong> {client.contactPerson}
                </Typography> */}
                <Typography variant="body1" className={classes.textMargin}>
                  <strong>Agent Code:</strong> {client.agentCode}
                </Typography>
                <Typography variant="body1" className={classes.textMargin}>
                  <strong>Phone:</strong> {client.phone}
                </Typography>
                {/* <Typography variant="body1" className={classes.textMargin}>
                  <strong>Address:</strong> {client.address}
                </Typography> */}
              </div>
            ) : (
              <Typography variant="body1" className={classes.textMargin}>
                Loading client details...
              </Typography>
            )}
          </div>
          {/* </Paper> */}
        </Drawer>
      </div>
      {/* Invoice and Reports Tabs */}
      <Grid
        item
        xs={12}
        sm={10}
        className={isMobile ? "" : classes.gridContainer}
      >
        <AppBar position="static" color="default">
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Bookings/Invoices" />
            <Tab label="Receipts" />
            <Tab label="Statement" />
          </Tabs>
        </AppBar>

        {/* Tab Panel */}
        <Box p={3}>
          <TextField
            id="search-bar"
            label="Search Bar"
            variant="outlined"
            fullWidth
            margin="normal"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {currentTab === 0 && filteredInvoices.length === 0 && (
            // Display no data message for "Daily Reports" tab
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                paddingTop: "20px",
                margin: "80px",
              }}
            >
              <NoData />
              <p
                style={{ padding: "40px", color: "gray", textAlign: "center" }}
              >
                No Orders for the selected Hotel/Agents
              </p>
            </div>
          )}
          {currentTab === 0 && filteredInvoices.length > 0 && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={headerStyle}>Number</TableCell>

                    <TableCell style={headerStyle}>Amount</TableCell>
                    <TableCell style={headerStyle}>Booking Date</TableCell>
                    <TableCell style={headerStyle}>Number of Pax</TableCell>
                    <TableCell style={headerStyle}>Created By</TableCell>
                    {user?.result?.userType === "admin" ? (
                      <TableCell style={headerStyle}>Actions</TableCell>
                    ) : (
                      <TableCell style={headerStyle}>View</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredRows.map((invoice) => (
                    <TableRow key={invoice._id}>
                      <TableCell style={tableStyle}>
                        {invoice.invoiceNumber}
                      </TableCell>
                      {/* <TableCell style={tableStyle}>
                        {invoice.client.name}
                      </TableCell> */}
                      <TableCell style={tableStyle}>
                        {invoice.total ? format(invoice.total) : ""}
                      </TableCell>
                      <TableCell style={tableStyle}>
                        {moment(invoice.date).format("Do MMM YYYY")}{" "}
                      </TableCell>
                      <TableCell style={tableStyle}>{invoice.pax}</TableCell>
                      <TableCell style={tableStyle}>
                        {findUserNameById(invoice.creator)}
                      </TableCell>
                      {user?.result?.userType === "admin" ? (
                        <TableCell style={tableStyle}>
                          {" "}
                          <IconButton
                            onClick={() => handleViewInvoice(invoice._id)}
                          >
                            <RemoveRedEyeOutlinedIcon
                              style={{ width: "15px", height: "15px" }}
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => handleEditInvoice(invoice._id)}
                          >
                            <BorderColorIcon
                              style={{ width: "15px", height: "15px" }}
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDeleteInvoice(invoice._id)}
                          >
                            <DeleteOutlineRoundedIcon
                              style={{ width: "15px", height: "15px" }}
                            />
                          </IconButton>
                        </TableCell>
                      ) : (
                        <TableCell style={tableStyle}>
                          {" "}
                          <IconButton
                            onClick={() => handleViewInvoice(invoice._id)}
                          >
                            <RemoveRedEyeOutlinedIcon
                              style={{ width: "15px", height: "15px" }}
                            />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell  style={tableStyle} colSpan={3}></TableCell>
                    <TableCell style={tableStyle}>
                      Total Pax:{" "}
                      {filteredRows.reduce(
                        (total, invoice) => total + invoice.pax,
                        0
                      )}
                    </TableCell>
                    <TableCell
                      colSpan={user?.result?.userType === "admin" ? 1 : 2}
                    ></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {currentTab === 1 && filteredReports.length === 0 && (
            // Display no data message for "Daily Reports" tab
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                paddingTop: "20px",
                margin: "80px",
              }}
            >
              <NoData />
              <p
                style={{ padding: "40px", color: "gray", textAlign: "center" }}
              >
                No reports for the selected Hotel/Agents
              </p>
            </div>
          )}
          {currentTab === 1 && filteredReports.length > 0 && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={headerStyle}>Date</TableCell>
                    <TableCell style={headerStyle}>Time</TableCell>
                    {/* <TableCell style={headerStyle}>Hotel/Agents Name</TableCell> */}
                    <TableCell style={headerStyle}>Created By</TableCell>
                    <TableCell style={headerStyle}>Payment</TableCell>
                    <TableCell style={headerStyle}>Payment Mode</TableCell>
                    <TableCell style={headerStyle}>Remarks</TableCell>
                    {user?.result?.userType === "admin" && (
                      <>
                        <TableCell style={headerStyle}>Edit</TableCell>
                        <TableCell style={headerStyle}>Delete</TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredReportsRows.map((row) => (
                    <TableRow key={row._id}>
                      {/* <TableCell style={tableStyle}>{row.client.name}</TableCell> */}
                      {/* <TableCell style={tableStyle}>
                     {row.client.address}
                   </TableCell> */}
                      <TableCell style={tableStyle}>
                        {moment(row.date).format("Do MMM YYYY")}
                      </TableCell>
                      <TableCell style={tableStyle}>
                        {moment(row.date).format(" h:mm:ss a")}
                      </TableCell>
                      {/* <TableCell style={tableStyle}>
                        {row.client.name}
                      </TableCell> */}
                      <TableCell style={tableStyle}>
                        {findUserNameById(row.creator)}
                      </TableCell>
                      {/* <TableCell style={tableStyle}>
                        {row.orderRecieved}
                      </TableCell> */}
                      <TableCell style={tableStyle}>
                        {row.payment && row.payment ? format(row.payment) : "-"}
                      </TableCell>
                      <TableCell style={tableStyle}>
                        {row.paymentMode}
                      </TableCell>
                      {/* <TableCell style={tableStyle}>
                        {" "}
                        {row.advance && row.advance.cash
                          ? `₹${row.advance.cash
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                          : "-"}
                      </TableCell>
                      <TableCell style={tableStyle}>
                        {row.advance && row.advance.cheque
                          ? `₹${row.advance.cheque
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                          : "-"}
                      </TableCell> */}
                      <TableCell style={tableStyle}>{row.remarks}</TableCell>
                      {user?.result?.userType === "admin" && (
                        <>
                          <TableCell
                            style={{ ...tableStyle, width: "10px" }}
                            onClick={() => handleInputChange(row._id)}
                          >
                            <IconButton>
                              <BorderColorIcon
                                style={{ width: "20px", height: "20px" }}
                              />
                            </IconButton>
                          </TableCell>
                          <TableCell style={{ ...tableStyle, width: "10px" }}>
                            <IconButton
                              onClick={
                                () => handleDeleteMeeting(row._id)

                                // alert("Report deleted")
                              }
                            >
                              <DeleteOutlineRoundedIcon
                                style={{ width: "20px", height: "20px" }}
                              />
                            </IconButton>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {currentTab === 2 && (
            <Statement
              invoices={filteredInvoices}
              receipts={filteredReports}
              client={client}
              history={history}
            />
          )}
          <Dialog open={showForm} onClose={handleCloseForm}>
            <DialogContent>
              <MeetingForm
                onClose={handleCloseForm}
                // onMeetingSubmit={handleMeetingSubmit}
                // currId={currId}
                // setCurrId={setCurrId}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseForm}>Cancel</Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Grid>
    </>
  );
};

export default Stats;
